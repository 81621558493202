import { useEffect, useState } from 'react'
import Linkify from 'react-linkify'
import '../MessageForm/MessageForm.css'
import { CommonService } from '../_services/common.service'
import { TrackingService } from '../_services/tracking.service'
import calendarIcon from '../assets/icons/calendar-tab.svg'
import { datesDifferent, formatDayDate } from '../misc/dates'
import SessionHistory from './SessionHistory'
import Trackers from './Trackers'
import CoachNotes from './coach-notes'
import Goals from './goals'

interface Task {
    _id: string
    label: string
    format: string
    createdAt: string
    over: boolean
}

const NotesTaskGoals = (props: any) => {
    const [istab, setTab] = useState('notes')
    const [tasksData, setTasksData] = useState(null as Task[] | null)
    const [coacheeName, setCoacheeName] = useState(null as string | null)
    const [taskMsg, setTaskMsg] = useState('')
    const [isEdit, setIsEdit] = useState(null as Task | null)
    const coacheeId = props?.fromCoachee?._id

    useEffect(() => {
        let coacheeName =
            'user' in props
                ? (
                      props?.user?.firstName +
                      ' ' +
                      props?.user?.lastName
                  ).toString()
                : null
        if (!coacheeName) {
            coacheeName =
                'fromCoachee' in props
                    ? (props?.fromCoachee?.fullName).toString()
                    : null
        }
        setCoacheeName(coacheeName)
    }, [props?.fromCoachee?._id])

    useEffect(() => {
        if (istab === 'tasks') {
            CommonService.getTasks(coacheeId, function (res: any) {
                if (!res.data.empty) {
                    res.data.reverse()
                    setTasksData(res.data)
                    setTaskMsg('')
                    setIsEdit(null)
                } else {
                    setTasksData(null)
                }
            })
        }
    }, [istab, coacheeId])

    const onChangeTab = (v: string) => {
        TrackingService.send('tab_selected', {
            name: v,
            coachee: coacheeId,
        })
        setTab(v)
    }

    const handleChange = (e: any) => {
        setTaskMsg(e.target.value)
    }

    const addSaveTask = (e: any) => {
        e.preventDefault()
        if (isEdit) {
            CommonService.updateTask(
                isEdit?._id,
                { label: taskMsg },
                function () {
                    if (tasksData) {
                        tasksData.map((item: Task) => {
                            if (item._id === isEdit._id) {
                                item.label = taskMsg
                            }
                        })
                    }
                    setTaskMsg('')
                    setIsEdit(null)
                },
            )
        } else {
            CommonService.createTask(
                coacheeId,
                { label: taskMsg },
                function (res: any) {
                    tasksData?.unshift(res.data)
                    setTaskMsg('')
                },
            )
        }
    }
    const onEditTask = (item: Task) => {
        setTaskMsg(item.label)
        setIsEdit(item)
    }

    const onDeleteTask = (oldItem: Task) => {
        CommonService.deleteTask(oldItem, function (res: any) {
            if (tasksData) {
                setTasksData(
                    tasksData.filter((item) => {
                        return item._id !== oldItem._id
                    }),
                )
            }
        })
    }

    return (
        <div className="wrapper-container tab-container">
            <div className="flex border-b border-gray-200 items-center pl-3 mb-3 pb-3 space-x-2">
                <img
                    width={40}
                    height={40}
                    src={props.image ?? ''}
                    className="rounded-[40px]"
                    alt="coachee-img"
                />
                <span className="font-semibold">
                    {props.user?.firstName} {props.user?.lastName}
                </span>
            </div>
            <ul className="nav nav-pills nav-justified nav-break px-2">
                <li className="nav-item flex whitespace-nowrap pr-2 pt-2">
                    <div
                        className={
                            istab === 'notes'
                                ? 'nav-link active pr-8'
                                : 'nav-link pr-8'
                        }
                        onClick={() => {
                            onChangeTab('notes')
                        }}
                    >
                        <img src="/assets/images/quill.png" />
                        &nbsp; My notes
                    </div>
                </li>
                <li className="nav-item flex whitespace-nowrap pr-2 pt-2">
                    <div
                        className={
                            istab === 'tasks'
                                ? 'nav-link active pr-8'
                                : 'nav-link pr-8'
                        }
                        onClick={() => {
                            onChangeTab('tasks')
                        }}
                    >
                        <img src="/assets/images/tasks.png" />
                        &nbsp; Tasks
                    </div>
                </li>
                <li className="nav-item flex whitespace-nowrap pr-2 pt-2">
                    <div
                        className={
                            istab === 'trackers'
                                ? 'flex nav-link active pr-8'
                                : 'nav-link pr-8'
                        }
                        onClick={() => {
                            onChangeTab('trackers')
                        }}
                    >
                        <img
                            src="/assets/images/stats.png"
                            className="flex h-6 w-6"
                        />
                        &nbsp; Engagement trackers
                    </div>
                </li>
                <li className="nav-item flex whitespace-nowrap pr-2 pt-2">
                    <div
                        className={
                            istab === 'goals'
                                ? 'nav-link active pr-8'
                                : 'nav-link pr-8'
                        }
                        onClick={() => {
                            onChangeTab('goals')
                        }}
                    >
                        <img src="/assets/images/mountain.png" />
                        &nbsp; Goals
                    </div>
                </li>
                <li className="nav-item flex whitespace-nowrap pr-2 pt-2">
                    <div
                        className={
                            istab === 'sessions'
                                ? 'nav-link active pr-8'
                                : 'nav-link pr-8'
                        }
                        onClick={() => {
                            onChangeTab('sessions')
                        }}
                    >
                        <div className="mr-2 flex items-center">
                            <img src={calendarIcon} />
                            {/* <svg
                                width="48"
                                height="48"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V8H20V21Z"
                                    fill="#ff7d48"
                                />
                            </svg> */}
                            &nbsp; Session history
                        </div>
                    </div>
                </li>
            </ul>
            <div className="tab-content">
                {istab === 'trackers' && (
                    <Trackers coacheeId={coacheeId}></Trackers>
                )}

                {istab === 'goals' && (
                    <div className={'tab-pane container active'} id="Goals">
                        <Goals coacheeId={coacheeId}></Goals>
                    </div>
                )}

                {istab === 'notes' && (
                    <div className={'tab-pane container active'} id="Goals">
                        <CoachNotes
                            coacheeId={coacheeId}
                            coacheeName={coacheeName}
                        />
                    </div>
                )}

                {istab === 'sessions' && (
                    <SessionHistory coacheeId={coacheeId}></SessionHistory>
                )}
                <div
                    className={
                        istab === 'tasks'
                            ? 'tab-pane container task-form active'
                            : 'tab-pane container task-form fade'
                    }
                    id="Tasks"
                >
                    <div className="pl-3 pr-3 tasks-overflow">
                        {tasksData &&
                            tasksData.map((item, i) => (
                                <div key={item._id}>
                                    {datesDifferent(
                                        new Date(item.createdAt),
                                        tasksData[i - 1]
                                            ? new Date(
                                                  tasksData[i - 1].createdAt,
                                              )
                                            : null,
                                        'days',
                                    ) && (
                                        <h6 className="semi-underline mt-4">
                                            {formatDayDate(
                                                new Date(item.createdAt),
                                            )}
                                        </h6>
                                    )}
                                    <div className="form-group checkbox-container">
                                        <div className="edit-list">
                                            <div className="dropdown-list">
                                                <button className="edit-task fa fa-ellipsis-h"></button>
                                                <ul className="dropdown-menu">
                                                    <li
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                            onEditTask(item)
                                                        }
                                                    >
                                                        Edit
                                                    </li>
                                                    <li
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                            onDeleteTask(item)
                                                        }
                                                    >
                                                        Delete
                                                    </li>
                                                </ul>
                                            </div>
                                            <label className="checkbox-custom d-block">
                                                <input
                                                    type="checkbox"
                                                    checked={item.over}
                                                    disabled={true}
                                                />
                                                <span>
                                                    <Linkify
                                                        componentDecorator={(
                                                            decoratedHref,
                                                            decoratedText,
                                                            key,
                                                        ) => (
                                                            <a
                                                                target="blank"
                                                                href={
                                                                    decoratedHref
                                                                }
                                                                key={key}
                                                            >
                                                                {' '}
                                                                {
                                                                    decoratedText
                                                                }{' '}
                                                            </a>
                                                        )}
                                                    >
                                                        {item.label}
                                                    </Linkify>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <form className="MessageForm" onSubmit={addSaveTask}>
                        <div className="input-container">
                            <input
                                type="text"
                                value={taskMsg}
                                onChange={handleChange}
                                placeholder="Type a task..."
                            />
                        </div>
                        <div className="button-container">
                            <button
                                type="submit"
                                className="fa fa-paper-plane"
                                onClick={addSaveTask}
                            ></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NotesTaskGoals
