import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { MessageForm } from '../MessageForm/message.form'
import { MessageList } from '../MessageList/message.list'

import * as Sentry from '@sentry/react'
import axios from 'axios'
import ChatSkeleton from '../_components/skeleton/ChatSkeleton'
import { userConstants } from '../_constants'
import { ChatService } from '../_services/chats.service'
import { TrackingService } from '../_services/tracking.service.js'
import { getFileType } from '../utils/chatUtils'

import { ChanelItem } from './ChanelItem'
import './chat.css'

export class Chat extends Component<any, any> {
    static propTypes = {
        videoView: PropTypes.bool,
    }

    static defaultProps = {
        videoView: false,
    }
    messageContainerRef: any
    paginator: null
    channelPaginatorInfo: any
    messageAddedCallbacks: any[]
    constructor(props: any) {
        super(props)
        this.channelPaginatorInfo = {}
        ;(this.messageAddedCallbacks = []),
            (this.messageContainerRef = React.createRef()),
            (this.paginator = null),
            (this.state = {
                messages: [],
                hasMoreMessages: false,
                loading: false,
                username: 'test',
                channel: null,
                individualChannelInfo: [],
                channeList: [],
                channelItem: {},
                urgentList: [],
                pausedList: [],
                channelId: null,
                isTyping: false,
                coachId: window.localStorage.getItem('userinfo')
                    ? JSON.parse(
                          window.localStorage.getItem('userinfo') ?? '{}',
                      )._id.toString()
                    : '',
            })
    }

    sortChannelList = () => {
        return new Promise((resolve) => {
            this.state.channeList.sort((a: any, b: any) => {
                return a.newestMesageDate < b.newestMesageDate ? 1 : -1
            })
            resolve(true)
        })
    }
    dedupeUrgentAndAllChats = () => {
        return new Promise((resolve) => {
            if (this.state.urgentList.length == 0) {
                resolve(true)
            }
            this.state.urgentList.forEach((urgent: any) => {
                for (let i = 0; i < this.state.channeList.length; i++) {
                    if (this.state.channeList[i]._id == urgent._id) {
                        this.state.channeList.splice(i, 1)
                    }
                }
                this.setState({ channeList: this.state.channeList })
                resolve(true)
            })
        })
    }
    dedupePausedAndAllChats = () => {
        return new Promise((resolve, reject) => {
            if (this.state.pausedList.length == 0) {
                resolve(true)
            }

            this.state.pausedList.forEach((paused: any) => {
                for (let i = 0; i < this.state.channeList.length; i++) {
                    if (this.state.channeList[i]._id == paused._id) {
                        this.state.channeList.splice(i, 1)
                    }
                }
                this.setState({ channeList: this.state.channeList })
                resolve(true)
            })
        })
    }
    componentWillUnmount = () => {
        if (this.messageContainerRef.current) {
            this.messageContainerRef.current.removeEventListener(
                'scroll',
                this.handleScroll,
            )
        }
        ChatService.setActiveChannel(null)

        for (let i = 0; i < this.messageAddedCallbacks.length; i++) {
            const channel = this.messageAddedCallbacks[i].channel
            const callback = this.messageAddedCallbacks[i].callback
            channel.removeListener('messageAdded', callback)
            channel.removeListener('messageRemoved', this.handleMessageRemoved)
        }
    }
    componentDidMount = () => {
        try {
            this.setState({ messages: [], loading: true })
            this.getChannelList()
                .then(async () => await ChatService.getChatClient('us1'))
                .then((chatClient) => this.getAllChannelInformation(chatClient))
                .then(async () => await ChatService.getChatClient('ie1'))
                .then((chatClient) => this.getAllChannelInformation(chatClient))
                .then(() => this.setState({ loading: false }))
                .then(this.sortChannelList)
                .then(this.selectActiveChannel)
                .catch((error) => {
                    this.setState({ loading: false })
                    Sentry.captureException(error)
                    this.addMessage({ body: `Error: ${error.message}` })
                })
        } catch (e) {
            this.setState({ loading: false })
            Sentry.captureException(e)
            console.log('Error: ', e)
        }
    }
    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any,
    ): void {
        if (this.messageContainerRef.current) {
            this.messageContainerRef.current.addEventListener(
                'scroll',
                this.handleScroll,
            )
        }
    }
    selectActiveChannel = () => {
        let channelList
        if (this.props.location?.search) {
            const query = new URLSearchParams(this.props.location.search)
            if (query.get('id')) {
                channelList = this.getChannelByCoachee(
                    query.get('id') as string,
                )
            }
        } else if (this.props.videoView == true) {
            channelList = this.getChannelByCoachee(this.props.fromCoachee._id)
        } else if (this.state?.channeList[0]) {
            channelList = this.state.channeList[0]
        } else {
            channelList = this.state.urgentList[0]
        }

        if (channelList && channelList.channel) {
            // @ts-ignore
            this.state.channel = channelList.channel
            ChatService.setActiveChannel(channelList.channel)
            this.setState({
                channelId: channelList._id,
                channelItem: channelList,
                messages: channelList.messages,
            })
            let olderMessagePaginator =
                this.channelPaginatorInfo[this.state.channel.sid]
            if (olderMessagePaginator[0].hasPrevPage === false) {
                this.channelPaginatorInfo[0] = this.channelPaginatorInfo[1]
            }
        } else {
            Sentry.captureMessage('CL1: ' + this.state.channeList.length)
            Sentry.captureMessage('CL2: ' + this.props.videoView)
            Sentry.captureMessage('CL3: ' + this.props.location?.search)

            if (channelList) {
                Sentry.captureMessage('CL4: ' + channelList._id)
            }
        }
    }
    getPausedList = (channels: any) => {
        const paused = channels.filter(
            (channel: any) => channel.coachee.status === 'paused',
        )

        this.setState({ pausedList: paused })
        ChatService.setPausedChannelList(paused)
    }
    getChannelList = () => {
        return new Promise((resolve, reject) => {
            ChatService.init()
                .then((data) => {
                    this.setState({ channeList: data })
                    this.getPausedList(data)
                    resolve(true)
                })
                .catch((e) => {
                    Sentry.captureException(e)
                    throw e
                })
        })
    }
    updateUnreadMessageCount = (channel: any, count: number) => {
        return ChatService.updateUnreadMessageCount(channel, count).then(
            (channelList) => {
                this.setState({ channeList: channelList })
            },
        )
    }
    updateChannelListAddMessages = (channel: any, message: string) => {
        for (let i = 0; i < this.state.channeList.length; i++) {
            if (this.state.channeList[i].twilio.id == channel.sid) {
                this.state.channeList[i].messages = [
                    ...this.state.channeList[i].messages,
                    message,
                ]
            }
        }
    }
    processChannels = (resolve: any, paginator: any) => {
        let promises = []
        for (let i = 0; i < paginator.items.length; i++) {
            promises.push(
                new Promise((resolve2, reject) => {
                    var channel = paginator.items[i]
                    // add unread message count to channel list
                    channel
                        .getUnreadMessagesCount()
                        .then(this.updateUnreadMessageCount.bind(null, channel))

                    let channelList: any = false
                    for (let i = 0; i < this.state.channeList.length; i++) {
                        if (channel.sid == this.state.channeList[i].twilio.id) {
                            channelList = this.state.channeList[i] as any
                            channelList.newestMesageDate = 0
                        }
                    }
                    if (channelList) {
                        channelList.messages = []
                        channelList.channel = channel
                        this.setState({ individaulChannelInfo: channelList })
                        this.configureChannelEvents(channel)
                        this.processMessages(channel, channelList)
                            .then(() => this.dedupeUrgentAndAllChats())
                            .then(() => this.dedupePausedAndAllChats())
                            .then(() => resolve2(true))
                    } else {
                        resolve2(true)
                    }
                }),
            )
        }

        if (paginator.hasNextPage) {
            return paginator.nextPage().then((nextPaginator: any) => {
                this.processChannels(resolve, nextPaginator)
            })
        } else {
            return Promise.all(promises).then(() => resolve(true))
        }
    }
    getAllChannelInformation = (chatClient: any) => {
        return new Promise((resolve, reject) => {
            chatClient.getSubscribedConversations().then((paginator: any) => {
                return this.processChannels(resolve, paginator)
            })
        })
    }
    handleScroll = () => {
        const container = this.messageContainerRef.current
        if (container.scrollTop === 0 && !this.state.loading) {
            this.fetchOlderMessages()
        }
    }
    getChannelByCoachee = (id: string) => {
        for (let i = 0; i < this.state.channeList.length; i++) {
            const channelList = this.state.channeList[i]
            if (channelList.coachee._id == id) {
                return channelList
            }
        }

        // if it was not on the channel list it has an urgent message in it
        for (let i = 0; i < this.state.urgentList.length; i++) {
            const channelList = this.state.urgentList[i]
            if (channelList.coachee._id == id) {
                return channelList
            }
        }

        // if it was not on the channel list and urgent list
        for (let i = 0; i < this.state.pausedList.length; i++) {
            const channelList = this.state.pausedList[i]
            if (channelList.coachee._id == id) {
                return channelList
            }
        }
    }
    fetchOlderMessages = () => {
        const container = this.messageContainerRef.current
        const currentScrollHeight = container.scrollHeight
        const currentScrollTop = container.scrollTop
        return new Promise((resolve) => {
            let olderMessagePaginator =
                this.channelPaginatorInfo[this.state.channel.sid][0]
            if (olderMessagePaginator && olderMessagePaginator.hasPrevPage) {
                olderMessagePaginator.prevPage().then((paginator: any) => {
                    if (olderMessagePaginator.hasPrevPage) {
                        this.channelPaginatorInfo[this.state.channel.sid][0] =
                            paginator
                    }

                    let promises = [] as any
                    const totalMessages = paginator.items.length
                    for (let i = 0; i < totalMessages; i++) {
                        const message = paginator.items[i]
                        promises.push(
                            this.processMessage(message, totalMessages, i),
                        )
                    }
                    Promise.all(promises)
                        .then((data) => {
                            this.setState({
                                messages: [...data, ...this.state.messages],
                            })
                        })

                        .then(() => {
                            setTimeout(() => {
                                container.scrollTop =
                                    container.scrollHeight -
                                    currentScrollHeight +
                                    currentScrollTop
                            }, 0)
                            resolve(true)
                        })
                })
            }
        })
    }

    processMessages = (channel: any, channelList: any) => {
        return new Promise((resolve) => {
            channel.getMessages(50).then((paginator: any) => {
                let promises = [] as any
                this.channelPaginatorInfo[channel.sid] = [paginator, paginator]
                this.mapChannelMessages(
                    paginator,
                    promises,
                    channelList,
                    resolve,
                )
            })
        }).catch((e) => {
            Sentry.captureException(e)
            console.log(e)
        })
    }

    mapChannelMessages = (
        paginator: any,
        promises: any,
        channelList: any,
        resolve?: any,
    ) => {
        const totalMessages = paginator.items.length
        for (let i = 0; i < totalMessages; i++) {
            const message = paginator.items[i]
            promises.push(
                this.processMessage(message, totalMessages, i, channelList),
            )
        }

        if (channelList) {
            Promise.all(promises)
                .then((data) => {
                    channelList.messages = [...data]
                    return true
                })
                .then(() => resolve(true))
        }
    }

    processMessage = (
        message: any,
        totalMessages: any,
        i: any,
        channelList?: any,
    ) => {
        return new Promise((resolve, reject) => {
            var isCochee = ''
            let isfirst = false

            // show any channels with urgant messages
            if (
                // @ts-ignore
                i == [totalMessages - 1] &&
                message.state.attributes.is_urgent === true &&
                channelList
            ) {
                this.state.urgentList.push(channelList)
            }

            // process message and add to channelList
            if (isCochee !== message.state.author) {
                isfirst = true
            } else {
                isfirst = false
            }

            isCochee = message.state.author
            if (this.state.coachId == message.state.author) {
                isCochee = ''
            }

            if (channelList && totalMessages == i + 1) {
                channelList.newestMesageDate = message.state.timestamp.getTime()
            }
            // grab the media data
            if (message.type === 'media') {
                const media = {
                    url: '#',
                    fileName: message.media.state.filename,
                    size:
                        Math.round(
                            (message.media.state.size / 1024 / 1024 +
                                Number.EPSILON) *
                                100,
                        ) / 100,
                    sizeMeasure: 'MB',
                    type: getFileType(message.media.state.contentType),
                    duration: message.state.attributes?.duration,
                    isMp3:
                        message.media.state.contentType == 'audio/mp3' ||
                        message.media.state.contentType == 'audio/mp4',
                }
                let data = this.getMessageObject(
                    message,
                    isfirst,
                    i,
                    totalMessages,
                    media,
                )

                // now lets set the url
                message.media
                    .getContentTemporaryUrl()
                    .then(this.updateMediaUrl.bind(null, data))
                    .then(resolve(data))
            } else {
                resolve(
                    this.getMessageObject(
                        message,
                        isfirst,
                        i,
                        totalMessages,
                        message.media,
                    ),
                )
            }
        })
    }

    updateMediaUrl = (data: any, url: any) => {
        data.media.url = url
    }

    getMessageObject = (
        message: any,
        isfirst: any,
        i: any,
        totalMessages: any,
        media: any,
    ) => {
        return {
            me: message.state.author === this.state.username,
            author: this.state.coachId == message.state.author,
            body: message.state.body,
            timestamp: message.state.timestamp,
            isfirst: isfirst,
            isLast: i == totalMessages,
            isUrgent: message.state.attributes.is_urgent,
            index: message.index,
            media: media,
            message: message,
            isDeletedForViruses:
                message.state.attributes.isDeletedForViruses || false,
        }
    }

    populateMessages = (id: string, paused: any) => {
        return new Promise((resolve, reject) => {
            let channel = null

            for (let i = 0; i < this.state.channeList.length; i++) {
                if (id == this.state.channeList[i].twilio.id) {
                    if (this.state.channeList[i].messages) {
                        this.setState({
                            messages: this.state.channeList[i].messages,
                        })
                    }
                    channel = this.state.channeList[i].channel
                }
            }

            if (!channel) {
                for (let i = 0; i < this.state.urgentList.length; i++) {
                    if (id == this.state.urgentList[i].twilio.id) {
                        if (this.state.urgentList[i].messages) {
                            this.setState({
                                messages: this.state.urgentList[i].messages,
                            })
                        }
                        channel = this.state.urgentList[i].channel
                    }
                }
            }

            if (paused) {
                for (let i = 0; i < this.state.pausedList.length; i++) {
                    if (id === this.state.pausedList[i].twilio.id) {
                        if (this.state.pausedList[i].messages) {
                            this.setState({
                                messages: this.state.pausedList[i].messages,
                            })
                        }
                        channel = this.state.pausedList[i].channel
                    }
                }
            }

            // @ts-ignore
            this.state.channel = channel
            ChatService.setActiveChannel(channel)
            resolve(channel)
        })
    }

    addMessage = (message: any) => {
        //const messageData = { ...message,  }
        this.setState({
            messages: [...this.state.messages, message],
        })
    }

    countWords = (str: string) => {
        str = str.replace(/(^\s*)|(\s*$)/gi, '')
        str = str.replace(/[ ]{2,}/gi, ' ')
        str = str.replace(/\n /, '\n')
        return str.split(' ').length
    }

    handleNewMessage = (
        message: any,
        isUrgent: any,
        event: any,
        voiceFile: any,
        duration: any,
    ) => {
        if (!message && !event?.target?.files && !voiceFile) {
            return
        }

        if (this.state.channel) {
            let lastMessageIndex = 0
            if (this.state.messages.length != 0) {
                const lastMessage =
                    this.state.messages[this.state.messages.length - 1]
                lastMessageIndex = lastMessage.index
            }

            // “attachment_type” (“audio_message” or “document”)
            // @TODO “duration” (in sec)
            // “is_urgent” (true / false)

            let attributes: any = {}
            attributes.is_urgent = false
            if (isUrgent) {
                attributes.is_urgent = true
            }

            if (duration) {
                attributes.duration = duration
            }

            if (message) {
                lastMessageIndex = lastMessageIndex + 1
                const requestOptions = {
                    method: 'post',
                    url: `${userConstants.BASE_URL}channel/${this.state.channelId}/message`,
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        nbrWord: this.countWords(message),
                        isUrgent: isUrgent,
                        fileBytesSize: 0,
                        twilioIndex: lastMessageIndex,
                    },
                }
                axios(requestOptions)

                TrackingService.send('chat_message_sent', {
                    nb_word: this.countWords(message),
                    is_urgent: isUrgent,
                    coachee_id: this.state.channelItem.coachee.user._id,
                })

                this.state.channel
                    .sendMessage(message, attributes)
                    .catch((e: any) => {
                        Sentry.captureException(e)
                        TrackingService.send('chat_message_error', {
                            nb_word: this.countWords(message),
                            is_urgent: isUrgent,
                            coachee_id: this.state.channelItem.coachee.user._id,
                            errorCode: e.code,
                            errorMessage: e.message,
                        })
                    })
            }

            let fileSize = 0
            let file = null
            if (event?.target?.files) {
                fileSize = event.target.files[0].size
                attributes.attachment_type = 'document'
                file = event.target.files[0]
            } else if (voiceFile) {
                TrackingService.send('voice_message_sent', {
                    nb_seconds: duration,
                    is_urgent: isUrgent,
                    coachee_id: this.state.channelItem.coachee.user._id,
                })

                fileSize = voiceFile.size
                attributes.attachment_type = 'audio_message'
                file = voiceFile
            }

            if (fileSize > 0) {
                lastMessageIndex = lastMessageIndex + 1
                const requestOptions = {
                    method: 'post',
                    url: `${userConstants.BASE_URL}channel/${this.state.channelId}/message`,
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        nbrWord: 0,
                        isUrgent: isUrgent,
                        fileBytesSize: fileSize,
                        twilioIndex: lastMessageIndex,
                    },
                }
                axios(requestOptions)

                this.onAttachmentUpload(file, this.state.channel, attributes)
            }
        }
    }

    isAllowedAttachmentContentType = (type: any) => {
        return (
            type.search(
                /pdf|docx|vnd.openxmlformats-officedocument.wordprocessingml.document|vnd.openxmlformats-officedocument.spreadsheetml.sheet|csv|jpg|jpeg|gif|png|xlsx|txt|mp3/i,
            ) >= 0
        )
    }

    onAttachmentUpload = (file: any, channel: any, attributes: any) => {
        if (this.isAllowedAttachmentContentType(file.type)) {
            let formData = new FormData()
            formData.append('file', file)
            channel.sendMessage(formData, attributes)
        } else {
            throw new Error('Can not attach file type' + file.type)
        }
    }

    handleChannelId = (item: any) => {
        this.setState({ channelId: item._id, channelItem: item, messages: [] })

        const paused = item.coachee.status === 'paused'

        this.populateMessages(item.twilio.id, paused)
            .then((channel: any) => {
                channel.setAllMessagesRead()
                this.updateUnreadMessageCount(channel, 0)
                let olderMessagePaginator =
                    this.channelPaginatorInfo[this.state.channel.sid]
                olderMessagePaginator[0] = olderMessagePaginator[1]
            })
            .catch((error) => {
                Sentry.captureException(error)
                this.addMessage({ body: `Error2: ${error.message}` })
            })
    }

    messageAddedCallback = (channel: any, message: any) => {
        for (let i = 0; i < this.state.urgentList.length; i++) {
            this.state.channeList.unshift(this.state.urgentList[i])
        }
        // @ts-ignore
        this.state.urgentList = []

        let channelList = ChatService.getChannelListByChannel(channel)

        if (!channelList) {
            channelList = ChatService.getPausedChannelListByChannel(channel)
        }

        if (channel.sid == this.state.channel.sid) {
            channel.setAllMessagesRead().then(() => {
                this.updateUnreadMessageCount(channel, 0)
            })
        }

        this.processMessage(message, 1, 0, channelList)
            .then((data) => {
                if (channel.sid == this.state.channel.sid) {
                    this.addMessage(data)
                }
                this.updateChannelListAddMessages(channel, data as string)
            })
            .then(() => this.dedupeUrgentAndAllChats())
            .then(() => this.sortChannelList())
    }

    // Add the handleMessageRemoved method
    handleMessageRemoved = (message: any) => {
        this.setState((prevState: any) => ({
            messages: prevState.messages.filter(
                (msg: any) => msg.message.sid !== message.sid,
            ),
        }))
    }

    configureChannelEvents = (channel: any) => {
        const callback = this.messageAddedCallback.bind(null, channel)
        channel.on('messageAdded', callback)
        this.messageAddedCallbacks.push({
            channel: channel,
            callback: callback,
        })

        channel.on('memberJoined', (member: any) => {
            this.addMessage({
                body: `${member.identity} has joined the channel.`,
            })
        })

        channel.on('memberLeft', (member: any) => {
            this.addMessage({
                body: `${member.identity} has left the channel.`,
            })
        })

        channel.on('typingStarted', () => {
            this.setState({ isTyping: true })
        })

        channel.on('typingEnded', () => {
            this.setState({ isTyping: false })
        })

        channel.on('messageRemoved', (message: any) => {
            this.handleMessageRemoved(message)
        })
    }

    render() {
        const UrgentListItems = this.state.urgentList.map(
            (item: any, i: number) => {
                return (
                    <ChanelItem
                        key={i}
                        {...item}
                        channelItem={this.state.channelItem}
                        onSelect={this.handleChannelId}
                    />
                )
            },
        )

        const pausedListItems = this.state.pausedList.map(
            (item: any, i: number) => {
                return (
                    <ChanelItem
                        key={i}
                        {...item}
                        channelItem={this.state.channelItem}
                        onSelect={this.handleChannelId}
                        paused
                    />
                )
            },
        )

        const allListItems = this.state.channeList.map(
            (item: any, i: number) => {
                return (
                    <ChanelItem
                        key={i}
                        elementKey={i}
                        {...item}
                        channelItem={this.state.channelItem}
                        onSelect={this.handleChannelId}
                    />
                )
            },
        )

        return (
            <section className="row">
                {this.state.loading &&
                    Object.keys(allListItems).length === 0 &&
                    Object.keys(UrgentListItems).length === 0 &&
                    Object.keys(pausedListItems).length === 0 && (
                        <div className="col-md-12">
                            <div className="chanels-container">
                                <ChatSkeleton />
                            </div>
                        </div>
                    )}

                {!this.state.loading &&
                    Object.keys(allListItems).length === 0 &&
                    Object.keys(UrgentListItems).length === 0 &&
                    Object.keys(pausedListItems).length === 0 && (
                        <div className="col-md-12">
                            <div className="chanels-container">
                                <h6>Your messages</h6>
                                <div
                                    style={{
                                        position: 'relative',
                                        minHeight: '60vh',
                                        width: '100%',
                                        height: '80%',
                                        paddingTop: '10%',
                                    }}
                                >
                                    <div
                                        style={{
                                            margin: 'auto',
                                            top: '50%',
                                            width: '30%',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <p style={{ color: '#CFCFCF' }}>
                                            No messages yet
                                        </p>
                                        <p>
                                            Your messages will appear when a
                                            coachee sends you a message
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                {((!this.state.loading &&
                    Object.keys(allListItems).length !== 0) ||
                    Object.keys(UrgentListItems).length !== 0 ||
                    Object.keys(pausedListItems).length !== 0) &&
                    this.props.videoView == false && (
                        <div className="col-md-5">
                            <div className="chanels-container">
                                <div className="chanels-grid">
                                    {Object.keys(UrgentListItems).length !==
                                        0 && (
                                        <div>
                                            <h6>Urgent</h6>
                                            <div>
                                                <ul className="list-group">
                                                    {UrgentListItems}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="chanels-grid">
                                    <h6>All chats</h6>
                                    <div>
                                        <ul className="list-group">
                                            {allListItems}
                                        </ul>
                                    </div>
                                </div>

                                <div className="chanels-grid">
                                    {Object.keys(pausedListItems).length !==
                                        0 && (
                                        <div>
                                            <h6>Paused account(s)</h6>
                                            <div className="opacity-70">
                                                <ul className="list-group">
                                                    {pausedListItems}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                {((!this.state.loading &&
                    Object.keys(allListItems).length !== 0) ||
                    Object.keys(UrgentListItems).length !== 0) && (
                    <div
                        className={
                            this.props.videoView == true
                                ? 'col-md-12'
                                : 'col-md-7'
                        }
                    >
                        {this.props.videoView == true && (
                            <div className="chat-header">
                                <h5>Chat</h5>
                                <button
                                    className="close"
                                    onClick={this.props?.closeChat}
                                >
                                    &times;
                                </button>
                            </div>
                        )}
                        <MessageList
                            messageRef={this.messageContainerRef}
                            messages={this.state.messages}
                            channel={this.state.channelItem}
                            isTyping={this.state.isTyping}
                            videoView={this.props.videoView}
                        />
                        <MessageForm
                            onMessageSend={this.handleNewMessage}
                            channel={this.state.channelItem.channel}
                            channelId={this.state.channelId}
                        />
                    </div>
                )}
            </section>
        )
    }
}
