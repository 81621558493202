import { entries, isString } from 'lodash'
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs'
import { ajax, AjaxConfig, AjaxError, AjaxResponse } from 'rxjs/ajax'
import { BaseModel } from '../../models/request/base.model'
import { RefreshTokenResponse } from '../../models/response/auth/auth-response'
import { HttpMethod, HttpStatusCode } from './../constants'
import { hydrateFromStorage } from './stoage'

const localStorage = window.localStorage as Storage
let accessToken: string | null = null

export interface RequestOptions<T> {
    body?: T
    query?: Record<string, number | string | string[]>
    silentErrors?: boolean
    successMessage?: string
    responseType?: AjaxConfig['responseType']
}

export interface Response<T = null> {
    status: number
    message?: string
    ok: boolean
    data: T | null
    total: number
}

const willTokenExpired = (token: string): boolean => {
    const payload = JSON.parse(window.atob(token.split('.')[1]))
    const expiryDate: number = payload.exp
    const currentDate = Math.floor(Date.now() / 1000)
    return currentDate - expiryDate > -60
}

const messageToActionMap: Record<number, Record<string, () => any>> = {
    // [HttpStatusCode.FORBIDDEN]: {
    //     'No passport with this user - agent combination': () =>
    //         // stores.auth.signOut(),
    // },
}

const handleRequestAutomaticActions = <T>(
    observable: Observable<Response<T>>,
) => {
    return observable.pipe(
        tap((response) => {
            messageToActionMap[response.status]?.[response.message ?? '']?.()
        }),
    )
}

const getRefreshTokenRequest = <T>(
    accessToken: string,
    headers: Record<string, string>,
    observable: Observable<T>,
    body?: BaseModel,
) => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')

    if (user) {
        accessToken = user.accessToken
    }

    return ajax<RefreshTokenResponse>({
        url: `${import.meta.env.VITE_APP_BASE_URL}auth/refresh`,
        body: { accessToken },
        method: HttpMethod.POST,
        headers,
        withCredentials: true,
    }).pipe(
        catchError((error) => {
            if (error instanceof AjaxError) {
                return of(
                    error as unknown as AjaxResponse<RefreshTokenResponse>,
                )
            }

            throw error
        }),
        switchMap((response) => {
            if (response.status !== HttpStatusCode.OK) {
                // @todo
                // stores.auth.signOut()
            } else {
                user.accessToken = response.response.accessToken
                localStorage.setItem('user', JSON.stringify(user))

                const token = response.response?.accessToken

                if (token) {
                    // Update authorization header and accessToken (if present in body)
                    headers.Authorization = `Bearer ${token}`

                    if (body?.accessToken) {
                        body.accessToken = token
                    }
                }
            }
            return observable
        }),
    )
}

export const getHeaders = () => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    const storedDeviceId: string | null = hydrateFromStorage('deviceId')

    if (user) {
        accessToken = user.accessToken
    }
    const headers: Record<string, string> = {}

    headers['Content-Type'] = 'application/json'
    headers['user-type'] = 'coach'
    headers['platform'] = 'coach'

    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`
    }

    if (storedDeviceId) {
        headers['device-id'] = storedDeviceId
    }

    return headers
}

export const cleanQueryParams = (params: any) => {
    const query = new URLSearchParams()

    entries(params).forEach(([key, value]) => {
        if (
            value === undefined ||
            value === null ||
            (Array.isArray(value) && value.length === 0)
        ) {
            return
        }

        query.set(key, Array.isArray(value) ? value.join(',') : '' + value)
    })
    return query
}

export const request = <M = any, N = null>(
    endpoint: string,
    method: HttpMethod,
    options?: RequestOptions<M>,
): Observable<Response<N>> => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')
    let tokenExpired = false

    if (user.accessToken) {
        tokenExpired = willTokenExpired(user.accessToken)
    }

    let url = import.meta.env.VITE_APP_BASE_URL + endpoint
    const headers = getHeaders()

    if (options?.query) {
        url += `?${cleanQueryParams(options.query)}`
    }

    let requestObservable = ajax({
        url,
        body: options?.body,
        method,
        headers,
        responseType: options?.responseType ?? 'text',
        // API is set up to only set the Access-Control-Allow-Credentials header for these routes
        withCredentials: url.includes('/auth/'),
    }).pipe(
        catchError((error) => {
            if (error instanceof AjaxError) {
                return of(error)
            }

            throw error
        }),
        map((response): Response<N> => {
            const ok =
                response.status >= HttpStatusCode.OK &&
                response.status < HttpStatusCode.BAD_REQUEST

            let data: any | null

            try {
                if (response.status === HttpStatusCode.NO_RESPONSE_DATA) {
                    data = null
                } else {
                    data = JSON.parse(response.response)
                }
            } catch (error) {
                data = response.response ?? null
            }

            if (!ok && !options?.silentErrors) {
                // @todo
                // stores.notifications.createNotification(
                //     NotificationType.ERROR,
                //     data ?? 'An error occured',
                // )
            }

            if (ok && options?.successMessage) {
                // @todo
                // stores.notifications.createNotification(
                //     NotificationType.INFO,
                //     options.successMessage,
                // )
            }

            return {
                data: ok ? data : null,
                status: response.status,
                message: isString(data) ? data : undefined,
                ok,
                total: data ? (Array.isArray(data) ? data.length : 1) : 0,
            }
        }),
    )

    requestObservable = handleRequestAutomaticActions(requestObservable)

    if (accessToken && tokenExpired) {
        return getRefreshTokenRequest(
            accessToken,
            headers,
            requestObservable,
            options?.body as any,
        )
    }

    return requestObservable
}
