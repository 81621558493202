import * as Sentry from '@sentry/react'
import {
    Conversation,
    Client as ConversationsClient,
} from '@twilio/conversations'
import axios from 'axios'
import md5 from 'md5'
import { userConstants } from '../_constants'
import { CoacheeService } from './coachee.service'

interface Channel {
    sid: string
    channel: any
    twilio: {
        id: string
        name: string
    }
    unreadCount: number
}

interface ChatToken {
    us1: { token: string | null; region: string | null }
    ie1: { token: string | null; region: string | null }
}

interface ChatClient {
    ie1: ConversationsClient | null
    us1: ConversationsClient | null
}

let channelLists: Channel[] = []
let pausedLists: Channel[] = []
let client: ChatClient = {
    ie1: null,
    us1: null,
}
let unreadCallBack: () => void
let activeChannel: Channel | null
let chatToken: ChatToken = {
    us1: { token: null, region: null },
    ie1: { token: null, region: null },
}

const getChannelLists = () => {
    return channelLists
}

const setActiveChannel = (channel: Channel | null) => {
    activeChannel = channel
}

const setPausedChannelList = (channelList: Channel[]) => {
    pausedLists = channelList
}

const refreshToken = async (region?: 'ie1' | 'us1') => {
    const tokenDetails = await getToken(true, region)
    if (region === 'ie1') {
        client.ie1?.updateToken(tokenDetails.token as string)
    } else {
        client.us1?.updateToken(tokenDetails.token as string)
    }
}

const getChatClient = async (region?: 'ie1' | 'us1') => {
    try {
        if (!client.us1) {
            const tokenDetails = await getToken(false, 'us1')
            client.us1 = new ConversationsClient(tokenDetails.token as string)
            client.us1.on('tokenAboutToExpire', () => refreshToken('us1'))
        }

        if (!client.ie1) {
            const tokenDetails = await getToken(false, 'ie1')
            client.ie1 = new ConversationsClient(tokenDetails.token as string, {
                region: tokenDetails.region as string,
            })
            client.ie1.on('tokenAboutToExpire', () => refreshToken('ie1'))
        }

        return client[region ?? 'us1']
    } catch (e) {
        Sentry.captureException(e)
        console.error(e)
    }
}

const getToken = async (refresh: boolean = false, region?: 'ie1' | 'us1') => {
    if (!chatToken[region ?? 'us1'].token || refresh) {
        try {
            let deviceID = md5(window.navigator.userAgent)
            let url = `${
                userConstants.BASE_URL
            }channel/tokens?deviceId=${deviceID.toString()}`

            const result = await axios.get(url)

            const regionIE1 = result.data
                .filter((result: any) => result.region === 'ie1')
                .pop()
            const regionUS1 = result.data
                .filter((result: any) => result.region === 'us1')
                .pop()

            chatToken['ie1'] = {
                token: regionIE1.token,
                region: regionIE1.region,
            }
            chatToken['us1'] = {
                token: regionUS1.token,
                region: regionUS1.region,
            }
        } catch (error) {
            Sentry.captureException(error)
            console.log(error)
        }
    }
    return chatToken[region ?? 'us1']
}

const init = async () => {
    await getChannelList()
    const client1 = await getChatClient('us1')
    if (client1) {
        await setChannelsIntoList(client1)
    }
    const client2 = await getChatClient('ie1')
    if (client2) {
        await setChannelsIntoList(client2)
    }
    return getChannelLists()
}

const setUnreadCallback = (callBack: () => void) => {
    unreadCallBack = callBack
}

const configureChannelEvents = (converstaion: Conversation) => {
    converstaion.on('messageAdded', (message) => {
        const id = JSON.parse(
            window.localStorage.getItem('userinfo') ?? '{}',
        )._id.toString()
        if (
            (!activeChannel || activeChannel?.sid != converstaion.sid) &&
            message.author !== id
        ) {
            converstaion
                .getUnreadMessagesCount()
                .then(() => updateUnreadMessageCount.bind(null, converstaion))
                .then(() => {
                    if (unreadCallBack) {
                        getTotalUnreadMessageCount().then(unreadCallBack)
                    }
                })
        }
    })
}

const getChannelList = () => {
    return CoacheeService.getCoacheeDetails().then((channels: Channel[]) => {
        channelLists = channels.slice(0, channels.length)
    })
}

const updateUnreadMessageCount = (
    conversation: Conversation,
    count: number,
) => {
    return new Promise((resolve, reject) => {
        for (let i = 0; i < channelLists.length; i++) {
            if (channelLists[i].twilio.id == conversation.sid) {
                channelLists[i].unreadCount = count
            }
        }

        if (unreadCallBack) {
            getTotalUnreadMessageCount().then(unreadCallBack)
        }
        resolve(channelLists)
    })
}

const setChannelsIntoList = (chatClient: ConversationsClient) => {
    return new Promise((resolve, reject) => {
        chatClient
            .getSubscribedConversations()
            .then((paginator) => {
                for (let i = 0; i < paginator.items.length; i++) {
                    var channel = paginator.items[i]

                    for (let i = 0; i < channelLists.length; i++) {
                        if (channel.sid == channelLists[i].twilio.id) {
                            channelLists[i].channel = channel
                            configureChannelEvents(channel)
                        }
                    }
                }
            })
            .then(() => {
                let promises = []
                for (let i = 0; i < channelLists.length; i++) {
                    if (channelLists[i].channel) {
                        promises.push(
                            channelLists[i].channel
                                .getUnreadMessagesCount()
                                .then(
                                    updateUnreadMessageCount.bind(
                                        null,
                                        channelLists[i].channel,
                                    ),
                                ),
                        )
                    }
                }

                Promise.all(promises).then((values) => {
                    resolve(channelLists)
                })
            })
    })
}

const getTotalUnreadMessageCount = (): Promise<number> => {
    return new Promise((resolve, reject) => {
        let totalCount = 0
        for (let i = 0; i < channelLists.length; i++) {
            if (channelLists[i].unreadCount) {
                totalCount = totalCount + channelLists[i].unreadCount
            }
        }
        resolve(totalCount)
    })
}

const getChannelListByChannel = (conversation: Conversation) => {
    for (let i = 0; i < channelLists.length; i++) {
        if (channelLists[i].channel?.sid == conversation?.sid) {
            return channelLists[i]
        }
    }
}

const getPausedChannelListByChannel = (conversation: Conversation) => {
    for (let i = 0; i < pausedLists.length; i++) {
        if (pausedLists[i].channel.sid == conversation.sid) {
            return pausedLists[i]
        }
    }
}

export const ChatService = {
    init,
    getChatClient,
    setActiveChannel,
    setPausedChannelList,
    getChannelList,
    updateUnreadMessageCount,
    getTotalUnreadMessageCount,
    setUnreadCallback,
    getChannelListByChannel,
    getPausedChannelListByChannel,
}
