import * as Sentry from '@sentry/browser'
import { useEffect, useState } from 'react'
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Availability } from '../AvailabilityPage/Availability'
import { Chat } from '../Chat/Chat'
import { Coachee } from '../Coachee/coachee'
import Notifications from '../Notifications/notifications-view'
import { Resources } from '../Resources/Resources'
import { Sessions } from '../Sessions/sessions'
import { userActions } from '../_actions/user.actions'
import { ChatService } from '../_services/chats.service'
import { NotificationService } from '../_services/notification.service'

function Home() {
    const [unReadCount, setUnReadCount] = useState(0)
    const userInfo = localStorage.getItem('userinfo')
    const user = JSON.parse(userInfo ?? '{}')
    const location = useLocation()

    useEffect(() => {
        NotificationService.init()
        // check that /me data is still stored.
        // if it is not but we are logged in then
        // refresh the data
        const user: any = localStorage.getItem('user')
        const userAccessToken = JSON.parse(user ?? '{}')
        if (!user && userAccessToken?.accessToken) {
            userActions.getMe()
        }

        if (user) {
            Sentry.setUser({ coachId: user._id })
            ChatService.init()
                // @ts-ignore
                .then(ChatService.setUnreadCallback(updateUnReadCount))
                .then(ChatService.getTotalUnreadMessageCount)
                .then((unReadCount) => {
                    setUnReadCount(unReadCount)
                })
        }
    }, [])

    function updateUnReadCount(unReadCount: number) {
        setUnReadCount(unReadCount)
    }

    return (
        <div className="container-fluid">
            <div className="row home-wrapper clearfix">
                <div className="col-md-2 bg-white left-menu">
                    <div>
                        <ul className="side-menu">
                            <li
                                className={
                                    location.pathname === '/home' ||
                                    location.pathname === '/'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link to={'/'}>
                                    <i className="menu-icon-video"></i> Sessions
                                </Link>
                            </li>

                            <li
                                className={
                                    location.pathname === '/home/availability'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link to={'/home/availability'}>
                                    <i className="menu-icon-calendar"></i>My
                                    availability
                                </Link>
                            </li>
                            <li
                                className={
                                    location.pathname === '/home/chat'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link to={'/home/chat'}>
                                    <i className="menu-icon-chat"></i> Chat{' '}
                                    {unReadCount > 0 && (
                                        <span className="count">
                                            {unReadCount}
                                        </span>
                                    )}
                                </Link>
                            </li>
                            <li
                                className={
                                    location.pathname === '/home/coachee'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link to={'/home/coachee'}>
                                    <i className="menu-icon-coachee"></i> My
                                    coachees
                                </Link>
                            </li>
                            <li
                                className={
                                    location.pathname === '/home/resources'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link to={'/home/resources'}>
                                    <i className="menu-icon-resources"></i>{' '}
                                    Resources
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <ul className="bottom-links">
                        <li
                            className={
                                location.pathname === '/home/about'
                                    ? 'active'
                                    : ''
                            }
                        ></li>
                        <li>
                            <a
                                target="_blank"
                                href={`https://sama-form.typeform.com/c/Mtcrejeg#userId=${encodeURIComponent(
                                    user._id,
                                )}`}
                            >
                                <i className="menu-icon-resources"></i>Feedback
                            </a>
                        </li>
                        <li>
                            <a href="mailto:info@sama.io">Contact us</a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-10 bg-pink home-right-side">
                    <Routes>
                        <Route path="/" element={<Sessions />} />
                        <Route
                            path="/availability"
                            element={<Availability />}
                        />
                        <Route
                            path="/chat"
                            element={<Chat location={location} />}
                        />
                        <Route path="/coachee" element={<Coachee />} />
                        <Route path="/resources" element={<Resources />} />
                        <Route
                            path="/notifications"
                            element={<Notifications />}
                        />
                        <Route path="*" element={<Navigate to="/home" />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export { Home }
