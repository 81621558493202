import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { format } from 'date-fns'
import moment from 'moment'
import ProfilePopPage from '../Sessions/profile.popup'
import { Tooltip } from '../_components/tooltip'
import groupHoverIcon from '../assets/icons/group2.svg'
import groupIcon from '../assets/icons/group.svg'
import { coachee } from '../_reducers/coachee.reducer'

const ChanelItem = (props: any) => {
    const {
        coachee: { user },
        image,
        unreadCount,
        messages,
    } = props || {}

    const [searchParams, setSearchParams] = useSearchParams()
    const [openProfile, setOpenProfile] = useState(false)

    const onSelectChannel = (item: any) => {
        props.onSelect(item)
    }

    useEffect(() => {
        if (
            searchParams.has('profileId') &&
            searchParams.get('profileId') === props.coachee._id
        ) {
            setOpenProfile(true)
        }
    }, [])

    const getDisplayDateTime = (time: any) => {
        const date = new Date(time)
        const today = new Date()

        if (
            date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
        ) {
            return moment(date).format('H:mm')
        } else {
            return moment(date).format('L')
        }
    }

    let lastMessage = null
    if (messages?.length > 0) {
        lastMessage = messages[messages?.length - 1]
    }

    return (
        <div>
            <li
                className={
                    props?.channelItem?._id === props?._id
                        ? 'list-group-item selected'
                        : 'list-group-item'
                }
                onClick={() => {
                    onSelectChannel(props)
                }}
            >
                <div className="media-block">
                    <div className="thumb-block">
                        <img
                            className="thumbnail"
                            src={image}
                            width="49px"
                            height="49px"
                            alt="profile picture"
                            onClick={() => setOpenProfile(true)}
                        />
                    </div>
                    {user?.company?.settings?.sessionGuests?.canInvite && (
                        <div className="group">
                            <Tooltip title="Can add colleague to session">
                                <img
                                    src={groupIcon}
                                    className="groupIcon"
                                    onMouseOver={(e) =>
                                        (e.currentTarget.src = groupHoverIcon)
                                    }
                                    onMouseOut={(e) =>
                                        (e.currentTarget.src = groupIcon)
                                    }
                                />
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div className="media-body">
                    <div className="top-frame">
                        <div className="name-block">
                            <div className="name">
                                {user?.firstName + ' ' + user?.lastName}
                                <ProfilePopPage
                                    key={user._id}
                                    {...props}
                                    openProfile={openProfile}
                                    fullName={
                                        user?.firstName + ' ' + user?.lastName
                                    }
                                    setOpenProfile={setOpenProfile}
                                />
                            </div>
                        </div>
                        {lastMessage && (
                            <div className="time">
                                {getDisplayDateTime(lastMessage.timestamp)}
                            </div>
                        )}
                    </div>
                    <div className="bottom-frame">
                        <div className="msg-block">
                            {lastMessage && (
                                <div className="unread-block">
                                    {lastMessage.body}
                                </div>
                            )}
                        </div>
                        {unreadCount > 0 && (
                            <div className="count">{unreadCount}</div>
                        )}
                    </div>
                </div>
            </li>
            {props?.paused && props.coachee?.pausedDate && (
                <div className="bg-[#effaff] flex py-1 justify-center items-center mb-2">
                    <i className="calendar-blue" />
                    <p>
                        Paused on{' '}
                        {format(
                            new Date(props.coachee?.pausedDate),
                            'dd/MM/yyyy',
                        )}
                    </p>
                </div>
            )}
        </div>
    )
}
export { ChanelItem }
